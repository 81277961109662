import { /*AUTH_LOGIN, AUTH_LOGOUT, AUTH_ERROR, AUTH_CHECK, AUTH_GET_PERMISSIONS,*/ AuthProvider, useNotify } from 'react-admin';
import axios from 'axios';
import decodeJwt from 'jwt-decode';
import { endpoint, wsEndpoint } from './App';

export const authProvider: AuthProvider = {
    login: ({ username, password }) => {
        let data = JSON.stringify({ username, password });

        return axios.post(`${endpoint}/login`, data, {
          headers: {
            'Content-Type': 'application/json',
              'Access-Control-Allow-Origin': '*'
          }
        }).then(res => {
          if (res.data.error || res.status !== 200) {
            throw new Error(res.data.error);
          }
          else {
            const token = res.data.token;
            const decodedToken = decodeJwt(token);
            localStorage.setItem('token', token);
            localStorage.setItem('username', res.data.username);
            localStorage.setItem('id', res.data.id);
            localStorage.setItem('first_name', res.data.first_name);
            localStorage.setItem('last_name', res.data.last_name);
            localStorage.setItem('role_id', res.data.role);
            localStorage.setItem('phone_extension', res.data.phone_extension);
            localStorage.setItem('auth', JSON.stringify(res.data));
            localStorage.setItem('subordinates_ids', JSON.stringify(res.data.subordinates_ids));
            localStorage.setItem('team_tasks_ids', JSON.stringify(res.data.team_tasks_ids));
            localStorage.setItem('head_of_dep', res.data.head_of_dep);
            localStorage.setItem('accountant', res.data.accountant);

            return Promise.resolve();
          }
        });
    },
    logout: () => {
       localStorage.removeItem('token');
       localStorage.removeItem('username');
       localStorage.removeItem('id');
       localStorage.removeItem('first_name');
       localStorage.removeItem('last_name');
       localStorage.removeItem('role_id');
       localStorage.removeItem('phone_extension');
       localStorage.removeItem('auth');
       localStorage.removeItem('subordinates_ids');
       localStorage.removeItem('team_tasks_ids');
       localStorage.removeItem('head_of_dep');
       localStorage.removeItem('accountant');
       return Promise.resolve();
    },
    checkError: (params) => {
        const { status } = params;
        if (status === 401 || status === 403) {
          localStorage.removeItem('token');
          localStorage.removeItem('username');
          localStorage.removeItem('id');
          localStorage.removeItem('first_name');
          localStorage.removeItem('last_name');
          localStorage.removeItem('role_id');
          localStorage.removeItem('phone_extension');
          localStorage.removeItem('auth');
          localStorage.removeItem('subordinates_ids');
          localStorage.removeItem('team_tasks_ids');
          localStorage.removeItem('head_of_dep');
          localStorage.removeItem('accountant');
          return Promise.reject();
        }
        return Promise.resolve();
    },
    checkAuth: () => {
      return localStorage.getItem('token') ? Promise.resolve() : Promise.reject({ redirectTo: '/login' })
    },
    getPermissions: () => {
        Promise.resolve([]);
    },
    getIdentity: () => {
        const { id, username, first_name, last_name, avatar } = JSON.parse(localStorage.getItem('auth') ?? '');
        return Promise.resolve({
            id: id,
            fullName: first_name + " " + last_name,
            avatar: avatar,
        });
    },
};