import React, { useState, useEffect } from 'react';
import {
    Create,
    SimpleForm,
    TextInput,
    SelectInput,
    NumberInput,
    ReferenceInput,
    AutocompleteInput,
    required,
    useRedirect,
    useDataProvider,
    DateTimeInput,
    useGetList,
    CreateBase, Form, Toolbar,
    useNotify,
    Loading,
} from 'react-admin';
import { endpoint, wsEndpoint } from '../App';
import {
    List,
    ListItem,
    ListItemAvatar,
    ListItemIcon,
    ListItemSecondaryAction,
    ListItemText,
    Checkbox,
    Typography,
    Box,
    Icon
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import axios from 'axios';
import { useSound } from 'use-sound';
import PhoneForwardedIcon from '@mui/icons-material/PhoneForwarded';
import PhoneDisabledIcon from '@mui/icons-material/PhoneDisabled';

const getIconByTalkTime = (talkTime) => {
    if(talkTime > 0){
        return <PhoneForwardedIcon style={{ color: 'green' }} />;
    } 
      
    return <PhoneDisabledIcon style={{ color: 'red' }} />;
};

export const WazzupChat = ({contact_id}) => {  
const [url, setUrl] = useState([]);
const [loading, setLoading] = useState(true);

useEffect(() => {
    fetchData();
}, []);

const fetchData = async () => {
    try {
      const token = localStorage.getItem('token'); // Get token from localStorage
      const reqBody = {
        manager_account_id: localStorage.getItem('id'),
        manager_name: localStorage.getItem('first_name') + " " + localStorage.getItem('last_name'),
        contact_id: contact_id,
      }
      const response = await axios.post(
        `${endpoint}/wazzup/iframe`, reqBody,
        {
          headers: {
            Authorization: `${token}`, // Set Authorization header with token
            'Content-Type': 'application/json', // Ensure correct content type
          },
        }
      );
      // Check if the response data is different from the current notifications
      console.log(new Date().toISOString() + " " + JSON.stringify(response.data));
      setUrl(response.data.url);
      setLoading(false);
    } catch (error) {
      console.error('Fetch failed:', error);
    }
};

  // Telphin Calls List Show
  return (
    <>
    {loading ? <Loading /> : (
      <iframe 
      src={url} 
      title='Wazzup Chat'
      allow="microphone *; clipboard-write *"
      style={{ width: '100%', height: '80vh', border: 'none' }} 
  />
    )}
    </>
  );

};