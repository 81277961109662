export const types = [
    //{id: 'comment', name: 'Написать комментарий к сделке'},
    
    //{id: 'db', name: 'Договор бронирования (ДБ) ИП Кучерук-Наместникова'},
    //{id: 'db_oz', name: 'Договор бронирования (ДБ) ИП Зулаева'},
    
    //{id: 'invoice', name: 'Счет на оплату'},

    //{id: 'dkp', name: 'Договор купли-продажи (ДКП)'},

    {id: 'db_luba', name: 'Договор бронирования (ДБ)'},
    {id: 'invoice_luba', name: 'Счет на оплату'},
    //{id: 'db_luba_new', name: '(Новый) Договор бронирования (ДБ) ИП Юсубова'},
    //{id: 'dkp_luba_new', name: '(Новый) Договор купли-продажи (ДКП) ИП Юсубова'},

    {id: 'dkp_luba', name: 'Договор купли-продажи (ДКП)'},

    //{id: 'act', name: 'Акт приема-передачи'},
];