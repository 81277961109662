export const formats = [
    {id: 'online', name: 'Онлайн-встреча'},
    {id: 'offline', name: 'Встреча в офисе'},
];

export const getFormatBgColor = (format) => {
    switch (format) {
        case 'online':
            return '#cff9ff';
        case 'offline':
            return '#F1F1F1';
        default:
            return '#F1F1F1';
    }
};