import { 
    Edit,
    EditBase,
    SimpleForm,
    TextInput,
    DateInput,
    ArrayInput,
    NumberInput,
    SimpleFormIterator,
    required,
    Form,
    Toolbar,
    useEditContext,
    useDataProvider,
    ShowButton,
    DeleteButton,
    SaveButton,
    useRedirect,
    BooleanInput,
} from 'react-admin';
import { Divider, Box, Grid, Button, Card, CardContent, Typography, Link } from '@mui/material';
import { ColorField, ColorInput } from 'react-admin-color-picker';

const FunnelEdit = () => {
    const dataProvider = useDataProvider();
    const redirect = useRedirect();

    const handleDelete = async (id) => {
        // Fetch the existing record to get the data before delete
        const existingRecord = await dataProvider.getOne('funnels', { id });

        // Modify the data or perform additional actions
        const modifiedData = {
            ...existingRecord.data,
             ap_id: localStorage.getItem('id'),
             ap_name: localStorage.getItem('first_name') + " " + localStorage.getItem('last_name'),
             table: 'funnels'
        };

        // Use dataProvider to send the delete request with modified data
        await dataProvider.create('delete', { data: modifiedData });

        // You can also perform additional actions after delete if needed
        //console.log(`Deleted record with ID: ${id}`);
        redirect(`/funnels`);
    };

    return (
        <EditBase redirect="list" 
             mutationMode="optimistic"
             transform={(data) => ({
                ...data,
                ap_id: localStorage.getItem('id'),
                ap_name: localStorage.getItem('first_name') + " " + localStorage.getItem('last_name'),
            })}
        >
            <FunnelEditContent onDelete={handleDelete} />
        </EditBase>
    );
}

const FunnelEditContent = ({ onDelete }) => {
    const { isLoading, record } = useEditContext();

    if (isLoading || !record) return null;

    return (
        <Box mt={2} display="flex">
            <Box flex="1" mb={10}>
                <Form>
                <Card>
                <CardContent>
                <h3>Редактирование воронки продаж</h3>
                <Box display="flex" style={{alignItems: 'center'}} flex="1" mb={3} >
                    <Box>
                        <Typography variant="body2">id воронки (для интеграций):</Typography>
                    </Box>
                    <Box ml={1}>
                        <Typography variant="body1">{record.id}</Typography>
                    </Box>
                </Box>
                <TextInput label="Название воронки" source="name" fullWidth validate={required()} />
                <TextInput label="Описание" source="description" fullWidth />
                <Divider />
                <ArrayInput label="Этапы" source="stages">
                    <SimpleFormIterator disableClear>
                         <ColorInput label="Цвет" source="color" picker="Sketch" validate={required()} />
                        <TextInput label="id этапа (латиницей)" source="id" helperText={false} validate={required()} fullwidth />
                        <TextInput label="Название этапа" source="name" helperText={false} validate={required()} fullwidth />
                        <BooleanInput label="Работа над заявкой на этом этапе завершена" source="request_done" />
                        <BooleanInput label="Этап является фактом что встреча назначена" source="meeting_scheduled" />
                        <BooleanInput label="Этап является онлайн-встречей с клиентом (видеозвонок)" source="online_meeting" />
                        <BooleanInput label="Этап является оффлайн-встречей с клиентом (вживую)" source="offline_meeting" />

                        {/*<Typography variant="h6">Авто-сообщение клиенту</Typography>
                        <Typography variant="body2">Добрый день, *Имя клиента*,</Typography>
                        <TextInput 
                            label="Тело сообщения клиенту" 
                            source="send_message" 
                            helperText="Напишите текст сообщения которое отправится клиенту в whatsapp/смс при переносе заявки на этот этап (оставьте пустым если ничего)" 
                            fullwidth 
                            multiline
                        />
                        <Typography variant="body2">С уважением, *Фамилия Имя менеджера*, земельный эксперт проекта "Наследие" компания-наследие.рф</Typography>*/}
                    </SimpleFormIterator>
                </ArrayInput>
                </CardContent>
                <CustomToolbar onDelete={() => onDelete(record.id)}/>
                </Card>
                </Form>
            </Box>
        </Box>
    );
};

/*const FunnelEditContent = () => {
    return (
        <Edit>
            <SimpleForm mb={15}>
                <TextInput label="Название воронки" source="name" validate={required()} />
                <TextInput label="Описание" source="description" />
                <Divider />
                <ArrayInput label="Этапы" source="stages">
                    <SimpleFormIterator >
                         <ColorInput label="Цвет" source="color" picker="Sketch" validate={required()} />
                        <TextInput label="id этапа (латиницей)" source="id" helperText={false} validate={required()} fullwidth/>
                        <TextInput label="Название этапа" source="name" helperText={false} validate={required()} fullwidth/>
                    </SimpleFormIterator>
                </ArrayInput>
            </SimpleForm>
        </Edit>
    );
};*/

const CustomToolbar = ({ onDelete }) => {
    return (
        <Toolbar>
            <SaveButton />
            <Box width={20} component="span" />
            {localStorage.getItem('role_id') <= 2 ? <Button style={{ color: 'red' }} onClick={onDelete} >Удалить</Button> : null}
        </Toolbar>
    );
};

export default FunnelEdit;