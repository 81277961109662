import * as React from 'react';
import { useState, useEffect, useRef, forwardRef, useImperativeHandle} from 'react';
import {
    List as RaList,
    SimpleListLoading,
    ReferenceField,
    TextField,
    EditButton,
    ShowButton,
    useListContext,
    ExportButton,
    SortButton,
    TopToolbar,
    CreateButton,
    Pagination,
    useGetIdentity,
    BulkActionsToolbar,
    BulkDeleteButton,
    BulkExportButton,
    RecordContextProvider,
    BooleanField,
    DateField,
    BooleanInput,
    Datagrid,
    ImageField,
    ChipField,
    Form,
    ReferenceInput,
    AutocompleteInput,
    DateInput
} from 'react-admin';
import {
    List,
    ListItem,
    ListItemAvatar,
    ListItemIcon,
    ListItemSecondaryAction,
    ListItemText,
    Checkbox,
    Typography,
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Chip 
} from '@mui/material';
import { Link } from 'react-router-dom';
import { ru } from 'date-fns/locale';
import { Avatar } from '../contacts/Avatar';
import '../crm.css';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PauseCircleOutlineIcon from '@mui/icons-material/PauseCircleOutline';
import Button from '@mui/material/Button';
import * as XLSX from 'xlsx';
import moment from 'moment';
import { formats, getFormatBgColor } from './formats';
import { statuses, getStatusBgColor } from './statuses';
import { makeStyles } from '@mui/styles';
import { usePopup } from '../design/PopupContext'; 
import { useTheme } from '@mui/material/styles'; 

const useStyles = makeStyles({
    tableCell: {
        borderRight: '1px solid rgba(224, 224, 224, 1)',
        padding: '8px 16px',
    },
    tableCellLast: {
        borderRight: 'none',
    },
    tableHead: {
        //backgroundColor: '#10793F !important',
        //color: '#fff'
    },
    tableHeadCell: {
        backgroundColor: '#10793F !important', // Set background color
        color: '#fff !important',              // Set text color
    },
});

const ListContent = () => {
    const classes = useStyles();
    const theme = useTheme();
    const { data, isLoading, setFilters, setSort } = useListContext();

    if (isLoading) {
        return <SimpleListLoading hasLeftAvatarOrIcon hasSecondaryText />;
    }

    // Group data by date
    const groupedData = data.reduce((acc, obj) => {
        const date = new Date(obj.date_time).toDateString();
        if (!acc[date]) {
            acc[date] = [];
        }
        acc[date].push(obj);
        return acc;
    }, {});

    return (
        <TableContainer component={Paper}>
            <Table stickyHeader>
                <TableHead className={classes.tableHead}>
                    <TableRow>
                        <TableCell className={`${classes.tableCell} ${classes.tableHeadCell}`}>Дата</TableCell>
                        <TableCell className={`${classes.tableCell} ${classes.tableHeadCell}`}>Время встречи</TableCell>
                        <TableCell className={`${classes.tableCell} ${classes.tableHeadCell}`}>Менеджер</TableCell>
                        <TableCell className={`${classes.tableCell} ${classes.tableHeadCell}`}>ФИО клиента</TableCell>
                        <TableCell className={`${classes.tableCell} ${classes.tableHeadCell}`}>Телефон</TableCell>
                        <TableCell className={`${classes.tableCell} ${classes.tableHeadCell}`}>Формат встречи</TableCell>
                        <TableCell className={`${classes.tableCell} ${classes.tableHeadCell}`}>Статус встречи</TableCell>
                        <TableCell className={`${classes.tableCell} ${classes.tableHeadCell} ${classes.tableCellLast}`}>Действия</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {Object.keys(groupedData).map((date) => (
                        <React.Fragment key={date}>
                            {groupedData[date].map((obj, index) => (
                                <RecordContextProvider key={obj.id} value={obj}>
                                    <TableRow
                                        component={Link}
                                        to={`/requests/${obj.request_id}`}
                                        style={{ textDecoration: 'none', color: 'inherit' }}
                                    >
                                        {index === 0 && (
                                            <TableCell className={classes.tableCell} rowSpan={groupedData[date].length}>
                                                <DateField source="date_time" locales="ru-RU" options={{
                                                    weekday: 'long',
                                                    year: 'numeric',
                                                    month: 'long',
                                                    day: 'numeric'
                                                }}/>
                                            </TableCell>
                                        )}
                                        <TableCell className={classes.tableCell}>
                                            <DateField source="date_time" showTime showDate={false} options={{ timeStyle: 'short' }} />
                                        </TableCell>
                                        <TableCell className={classes.tableCell}>{obj.manager_name}</TableCell>
                                        <TableCell className={classes.tableCell}>{obj.contact_name}</TableCell>
                                        <TableCell className={classes.tableCell}>{obj.contact_phone}</TableCell>
                                        <TableCell className={classes.tableCell}>
                                        <Chip
                                                label={formats.find(f => f.id === obj.format).name}
                                                sx={{
                                                    backgroundColor: getFormatBgColor(obj.format),
                                                    color: '#000000'
                                                }}
                                            />
                                        </TableCell>
                                        <TableCell className={classes.tableCell}>
                                        <Chip
                                                label={statuses.find(f => f.id === obj.status).name}
                                                sx={{
                                                    backgroundColor: getStatusBgColor(obj.status),
                                                    color: '#FFFFFF'
                                                }}
                                            />
                                        </TableCell>
                                        <TableCell className={`${classes.tableCell} ${classes.tableCellLast}`}>
                                            <EditButton />
                                        </TableCell>
                                    </TableRow>
                                </RecordContextProvider>
                            ))}
                            <TableRow>
                                <TableCell size="small" padding="none" colSpan={8} sx={{ height: '2px', backgroundColor: theme.palette.text.primary }} />
                            </TableRow>
                        </React.Fragment>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

const ListActions = ({handleSelectedManagerFilterChange, currentManagerFilter, handleHODFilterChange, defaultStartDate, defaultEndDate, handleStartDateChange, handleEndDateChange}) => {
  const { openPopup } = usePopup();

  return (
    <TopToolbar>
        <Form className='filterForm'>
            <Box display="flex" flexDirection="row" mb={1} ml={2} mt={2} alignItems="center">
            <DateInput
                    label="Начало периода"
                    source="startDate"
                    defaultValue={defaultStartDate}
                    onChange={handleStartDateChange}
                    style={{ marginRight: '1rem' }}
                />
                <DateInput
                    label="Окончание периода"
                    source="endDate"
                    defaultValue={defaultEndDate}
                    onChange={handleEndDateChange}
                    style={{ marginRight: '1rem' }}
                />
            </Box>
        </Form>
        <Form className="tasks-views">
              <ReferenceInput source="manager_account_id" reference="sales" filter={{ 'head_of_dep': true}}>
                  <AutocompleteInput label="Отдел РОПа" onChange={handleHODFilterChange} optionText={(choice) => `${choice.first_name} ${choice.last_name}`} />
              </ReferenceInput>
        </Form>
        <Form className="tasks-views">
              <ReferenceInput source="manager_account_id" reference="sales" filter={{ 'sales_id_for_requests': localStorage.getItem('id') }}>
                  <AutocompleteInput label="Менеджер" defaultValue={currentManagerFilter} onChange={handleSelectedManagerFilterChange} optionText={(choice) => `${choice.first_name} ${choice.last_name}`} />
              </ReferenceInput>
        </Form>
        <Button variant="contained" color="primary" sx={{ marginLeft: 2, marginTop: 1 }} onClick={openPopup} >
            + Новая встреча
        </Button>
    </TopToolbar>
)
};

const MeetList = () => {
    const { identity } = useGetIdentity();
    const [currentManagerFilter, setCurrentManagerFilter] = useState();
    const [currentHODFilter, setCurrentHODFilter] = useState();
    const [currentStartDateFilter, setCurrentStartDateFilter] = useState(moment.utc().startOf('month').toDate());
    const [currentEndDateFilter, setCurrentEndDateFilter] = useState(moment.utc().endOf('month').toDate());
    const [defaultDates, setDefaultDates] = useState({
        default_start_time: moment.utc().startOf('month').toDate(),
        default_end_time: moment.utc().endOf('month').toDate(),
    });
    //key !== 'date_time_before' lt && key !== 'date_time_after' ge && key !== 'team_members_head_id')

    useEffect(() => {
        const storedManagerFilter= localStorage.getItem('currentManagerFilter');
        if(storedManagerFilter){
            setCurrentManagerFilter(storedManagerFilter);
  
        }
        if(storedManagerFilter == ""){
            setCurrentManagerFilter("");

        }
    }, [currentManagerFilter]);

    const handleSelectedManagerFilterChange = (value) => {
        setCurrentManagerFilter(value);
        localStorage.setItem('currentManagerFilter', value);
    };

    return identity ? (
        <RaList
            actions={<ListActions 
                handleSelectedManagerFilterChange={handleSelectedManagerFilterChange}
                currentManagerFilter={currentManagerFilter} 
                handleHODFilterChange={(value) => setCurrentHODFilter(value)}
                defaultStartDate={currentStartDateFilter}
                defaultEndDate={currentEndDateFilter}
                handleStartDateChange={(event) => setCurrentStartDateFilter(event.target.value ? moment.utc(event.target.value).startOf('day').toISOString() : null)}
                handleEndDateChange={(event) => setCurrentEndDateFilter(event.target.value ? moment.utc(event.target.value).endOf('day').toISOString() : null)}
            />}
            perPage={1000}
            pagination={false}
            empty={false}
            sort={{ field: 'date_time', order: 'ASC' }}
            filter={{
                ...(currentHODFilter && { team_members_head_id: currentHODFilter}), 
                ...(currentManagerFilter && { manager_account_id: currentManagerFilter }),
                ...(currentStartDateFilter && { date_time_ge: currentStartDateFilter}),
                ...(currentEndDateFilter && { date_time_lt: currentEndDateFilter}),
            }}
        >
            <ListContent />
        </RaList>
    ) : null;
};

export default MeetList;